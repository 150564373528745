import {Component, Input} from '@angular/core';
import {SidebarComponent} from '../../molecules/sidebar/sidebar.component';
import {DropDownMenuComponent} from '../../organisms/drop-down-menu/drop-down-menu.component';

@Component({
  selector: 'main-layout',
  standalone: true,
  imports: [SidebarComponent, DropDownMenuComponent],
  template: `
    <div>

    </div>
    <nav class="h-20 flex justify-between items-center bg-gray-800 text-white p-4">
      <h1 class="text-xl font-bold">
        Pavlova
      </h1>
      <drop-down-menu></drop-down-menu>
    </nav>
    <div class="flex flex-grow  w-full h-full">
      <aside class=" text-white w-64">
        <app-sidebar/>
      </aside>
      <main class="flex-grow container mx-auto overflow-y-auto">
        <ng-content></ng-content>
      </main>
    </div>
    <footer class="bg-gray-800 text-center p-4">Footer</footer>
  `,
  styleUrl: './main-layout.component.css',
})
export class MainLayoutComponent {
}
