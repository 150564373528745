import { CommonModule } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  template: `
    <div class="w-[200px] rounded-md hover:duration-300 h-full">
      <div
        class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer text-white"
        [routerLink]="routerLink()"
        [routerLinkActive]="routerLinkActive()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="w-5 h-4 text-white pointer-events-none"
          fill="white"
        >
          <path [attr.d]="path()" />
        </svg>
        <span class="text-[15px] ml-4 text-gray-200 font-medium">{{
          label() | titlecase
        }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./card.component.css'],
})
export class CardComponent {
  public label = input<string>();
  public path = input<string>();
  public routerLink = input<string>();
  public routerLinkActive = input.required<string>();
}
