import {Router} from '@angular/router';
import {computed, inject, Injectable, Signal, signal} from '@angular/core';
import {AuthenticationDetails, CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import {environment} from '../../environments/environment';
import {AuthStatus} from '../shared/interfaces/auth-status.enum';
import {LoginResponse} from '../shared/interfaces/cognito-response.interface';
import {Observable} from 'rxjs';
import {CognitoJwtVerifier} from 'aws-jwt-verify';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userPool: any;
  cognitoUser: any;
  username: string = "";
  private router = inject(Router);
  private _currentUser = signal<any>(null);
  public currentUser: Signal<string> = computed(this._currentUser);
  private _userRole = signal<any>(null);
  public userRole = computed(this._userRole);
  private _authStatus = signal<AuthStatus>(AuthStatus.checking);
  public authStatus = computed(this._authStatus);

  constructor() {
    this.checkStatus()
  }


// Login
  public login(emailAddress: string, password: string): Observable<boolean> {
    return new Observable((observer) => {
      console.log("clicked")
      this._currentUser.set(null);
      this._userRole.set(null);
      // this._authStatus.set(AuthStatus.notAuthenticated);

      let authenticationDetails = new AuthenticationDetails({
        Username: emailAddress,
        Password: password,
      });

      let poolData = {
        UserPoolId: environment.cognitoUserPoolId,
        ClientId: environment.cognitoAppClientId,
      };
      this.username = emailAddress;
      this.userPool = new CognitoUserPool(poolData);
      let userData = {Username: emailAddress, Pool: this.userPool};
      this.cognitoUser = new CognitoUser(userData);

      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result: LoginResponse) => {
          this._currentUser.set(result.idToken.payload["cognito:username"]);
          this._authStatus.set(AuthStatus.authenticated);
          this._userRole.set((result.idToken.payload as any)["custom:role"]);
          console.log(result);
          localStorage.setItem('token', result.idToken.jwtToken);
          observer.next(true);
          observer.complete();
        },
        onFailure: (error: any) => {
          this._authStatus.set(AuthStatus.notAuthenticated);
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  // First time login attempt - New password require
  public changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
    if (!this.cognitoUser) {
      console.error("No hay un usuario autenticado para cambiar la contraseña");
      return;
    }
    if (newPassword === confirmPassword) {
      this.cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: () => {
            console.log("Reset Success");
            this.router.navigateByUrl("/auth/login");
          },
          onFailure: (error: any) => {
            console.error("Reset Fail", error);
          },
        }
      );
    } else {
      console.log("Password didn't Match");
    }
  }

  // Logout
  public logOut() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    this.cognitoUser = this.userPool.getCurrentUser();
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
    }
    this._authStatus.set(AuthStatus.notAuthenticated);
    this._userRole.set(null);
    this._currentUser.set(null);
    localStorage.removeItem('token');
    this.cognitoUser = null;
    this.router.navigateByUrl("/auth/login");
  }

  protected async checkStatus() {
    this._authStatus.set(AuthStatus.checking);
    const token = localStorage.getItem('token');
    if (!token) {
      console.log("No token found in localStorage");
      this._authStatus.set(AuthStatus.notAuthenticated)
      return false;
    }
    const verifier = CognitoJwtVerifier.create({
      userPoolId: environment.cognitoUserPoolId,
      tokenUse: "id",
      clientId: environment.cognitoAppClientId,
    });
    try {
      const payload = await verifier.verify(token);
      console.log(payload);
      this._currentUser.set(payload['cognito:username'])
      this._userRole.set(payload['custom:role']);
      this._authStatus.set(AuthStatus.authenticated);
      console.log(this._userRole());
      return true;
    } catch {
      console.log("Token not valid!");
      this._authStatus.set(AuthStatus.notAuthenticated);
      return false;
    }
  }
}

