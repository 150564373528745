import {Component, effect, HostListener, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomButtonComponent} from '../../atoms/custom-button/custom-button.component';
import {ICONS} from '../../atoms/icons/icons.constants';
import {DropDownItemComponent} from '../../atoms/drop-down-item/drop-down-item.component';
import {AuthService} from '../../../../data/auth-service';
import {Router} from '@angular/router';

@Component({
  selector: 'drop-down-menu',
  standalone: true,
  imports: [CommonModule, CustomButtonComponent, DropDownItemComponent],
  template: `
    <div>
      <div class="relative inline-block text-left">
        <custom-button
          [label]="userName() || 'User'"
          [iconSvgPath]="chevronPath"
          [buttonClasses]="'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'"
          (buttonClick)="toggleDropdown()"
        />

        @if (isOpen) {
          <div
            class="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          >
            <div class="py-1">
              @for (option of options; track $index) {
                <drop-down-item
                  [label]="option"
                  (optionClick)="handleOptionClick(option)"
                />
              }
            </div>
          </div>
        }
      </div>
    </div>
  `,
  styleUrl: './drop-down-menu.component.css'
})
export class DropDownMenuComponent {

  protected isOpen: boolean = false;
  protected chevronPath: string = ICONS.chevronDown

  public options: string[] = ['Cuenta', 'Cerrar Sesión'];

  private router = inject(Router);
  private authService = inject(AuthService);
  public currentUser = this.authService.currentUser;

  protected userName() {
    return this.currentUser().split('.')[0];
  }

  protected toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  // Close menu when click outside the mmenu
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    if (!element.closest('.relative')) {
      this.isOpen = false;
    }
  }

  protected handleOptionClick(option: string) {
    if (option === this.options[0]) {
      this.router.navigateByUrl('/profile');
      console.log('routed to profile');
    }
    if (option === this.options[1]) {
      this.authService.logOut();
    }
  }

}
