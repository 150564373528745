import {Component, inject} from '@angular/core';
import {CardComponent} from '../../atoms/card/card.component';
import {ICONS} from '../../atoms/icons/icons.constants';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AuthService} from '../../../../data/auth-service';
import {RolesEnum} from '../../../../shared/interfaces/roles.enum';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CardComponent, RouterLink, RouterLinkActive, CommonModule],
  template: `
    <div class="flex flex-col bg-gray-800 w-[210px] h-screen justify-items-start">

      @for (item of filteredMenuItems; track $index) {
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }">
        </ng-container>
      }

    </div>

    <ng-template #itemTemplate let-item>
      <app-card
        [label]="item.label"
        [path]="getIcon(item.path)"
        [routerLink]="item.path"
        routerLinkActive="bg-gray-600"
      />
    </ng-template>
  `
  ,
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {

  public authService = inject(AuthService);

  public menuItems = [
    {path: '/dashboard/home', label: 'Home'},
    {path: '/dashboard/calendar', label: 'Calendar'},
    {path: '/dashboard/stores', label: 'Stores'},
    {path: '/dashboard/products', label: 'Products'},
    {path: '/dashboard/stock', label: 'Stock'},
  ];
  private rolePermissions = {
    [RolesEnum.ADMIN]: [
      '/dashboard/home',
      '/dashboard/calendar',
      '/dashboard/stores',
      '/dashboard/products',
      '/dashboard/stock',
    ],
    [RolesEnum.MANAGER]: [
      '/dashboard/home',
      '/dashboard/calendar',
      '/dashboard/stock',
    ],
    [RolesEnum.CASHIER]: ['/dashboard/home'],
  };

  get filteredMenuItems() {
    const userRole = this.authService.userRole() as RolesEnum;
    const allowedPaths = this.rolePermissions[userRole] || [];
    return this.menuItems.filter((item) => allowedPaths.includes(item.path));
  }

  public getIcon(path: string): string {
    switch (path) {
      case '/dashboard/home':
        return ICONS.home;
      case '/dashboard/stores':
        return ICONS.stores;
      case '/dashboard/products':
        return ICONS.products;
      case '/dashboard/calendar':
        return ICONS.calendar;
      case '/dashboard/stock':
        return ICONS.products;
      default:
        return '';
    }
  }
}
