import {Component, input, output} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'custom-button',
  standalone: true,
  imports: [CommonModule],
  template: `
    <button
      [ngClass]="buttonClasses()"
      [type]="type()"
      [disabled]="disabled()"
      (click)="onClick()"

    >
      <ng-content>
        {{ label() }}
      </ng-content>

      @if (iconSvgPath() !== ""){
        <svg
          class="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path [attr.d]="iconSvgPath()"/>
        </svg>
      }

    </button>
  `,
  styleUrl: './custom-button.component.css',
})
export class CustomButtonComponent {
  public label = input<string>();
  public disabled = input<boolean>();
  public buttonClasses = input<string>("inline-flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:duration-300 hover:bg-gray-600 transition-transform duration-100 ease-in-out active:scale-95  ")
  public type = input<'button' | 'submit' | 'reset'>();
  public iconSvgPath = input<string>("");
  public buttonClick = output<void>();

  public onClick() {
    this.buttonClick.emit();
  }
}
