export interface Environment {
  production: boolean;
  cognitoUserPoolId: string;
  cognitoAppClientId: string;
}

export const environment: Environment = {
  production: false,
  cognitoUserPoolId: 'us-west-2_3L1UEUTek',
  cognitoAppClientId: '2q12m1iv772bhi20b20gvhnlvl',
};
