import {Component, input, output} from '@angular/core';

@Component({
  selector: 'drop-down-item',
  standalone: true,
  imports: [],
  template: `
    <a
      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      (click)="onClick()"
    >{{ label() }}
    </a>
  `,
  styleUrl: './drop-down-item.component.css'
})
export class DropDownItemComponent {

  public label = input.required<string>();

  public optionClick = output<void>();
  public onClick(){
    this.optionClick.emit();
  }
}
